<template>


  <!-- 图片 -->
  <template v-if="column.tag==='image' && value">
    <span v-if="value.length===0">未上传</span>
    <el-image v-for="(image,image_index) in value"
              style="width: 50px; height: 50px;margin-right: 10px;border-radius: 4px;" :key="image_index"
              :src="appStore.downloadUrl(image.id)"
              :preview-src-list="value.map(mapper=>appStore.downloadUrl(mapper.id))"
              :initial-index="image_index"
              :zoom-rate="1.2"
              :max-scale="7"
              :min-scale="0.2"
              :preview-teleported="true"
              fit="fill">
    </el-image>
  </template>
  <!-- 日期 -->
  <span v-else-if="column.tag === 'date' && value" v-html="showDate(value)"></span>
  <!-- 地址 -->
  <template v-else-if="column.tag === 'address' && value">
    {{ value.join(' ') }}
  </template>
  <!-- 附件 -->
  <template v-else-if="column.tag==='attachment' && value">
    <span v-if="value.length===0">未上传</span>
    <span v-else>
					<el-popover placement="bottom-start" :width="400" trigger="click">
						<attachmentItem :value="value" :background="'#F1F2F3'" color="#616161" width="98%"></attachmentItem>
            <template #reference>
              	<span style="font-size: 12px;color:#589EF8;cursor: pointer"
                      :title='value.map(mapper=>mapper.file_name).toString().replace(/,/g,"\n")'>
							    {{ ellipsis(value[0].file_name) }} {{ ` (共${value.length}个附件)` }}
						    </span>
            </template>
					</el-popover>
				</span>
  </template>
  <!-- 子表单 -->
  <template v-else-if="column.tag==='subForm' && value && value.length>0">
    <el-popover placement="bottom-start" width="fit-content" trigger="click">
      <el-table :data="value" size="small" border style="width:100%;border-radius: 6px;" max-height="30vh"
                :header-cell-style="{color:'#181A1D'}">
        <el-table-column label="序号" type="index" width="55px" fixed align="center"></el-table-column>
        <el-table-column v-for="(child,childIndex) in column.children" :key="childIndex" :label="child.label"
                         align="center"
                         :width="child.tag==='image'||child.tag==='attachment'?'200px':'130px'">
          <template v-slot:default="scope">
            <column-show :column="child" :value="scope.row[child.id]" :container="container"></column-show>
          </template>
        </el-table-column>
      </el-table>
      <template #reference>
        <el-button size="small" link type="primary" style="font-size: 13px">子表单 (行数{{ value.length }})</el-button>
      </template>
    </el-popover>
  </template>
  <!-- 开关 -->
  <template v-else-if="column.tag==='switch'">{{ value ? '是' : '否' }}</template>
  <!-- 人员 -->
  <template v-else-if="column.tag==='people'">
    <user-card v-for="(user,index) in users" :key="index" :value="user">
      <el-tag effect="dark" style="margin: 4px;cursor: pointer" round>
        <div style="display: flex;align-items: center;">
          <span style="margin-left: 2px"> {{ user.real_name }}</span>
        </div>
      </el-tag>
    </user-card>
  </template>
  <!-- 部门 -->
  <template v-else-if="column.tag==='dept'">
    <dept-card v-for="(dept,index) in depts" :key="index" :value="dept">
      <el-tag effect="dark" style="margin: 4px;cursor: pointer" round>
        <div style="display: flex;align-items: center;">
          <span style="margin-left: 2px"> {{ dept.name }}</span>
        </div>
      </el-tag>
    </dept-card>
  </template>
  <!-- 关联表单 -->
  <template v-else-if="column.tag==='referenceForm'">
    <span v-if="showValue && showValue==='表单已删除'" style="color: #E57470">{{ showValue }}</span>
    <el-button v-else type="primary" link size="small" @click="handleClickReferenceForm()">{{ showValue }}</el-button>
  </template>
  <!-- 关联表单多选 -->
  <template v-else-if="column.tag==='referenceFormSelect'">
    <el-link v-for="(item,index) in value" :key="index" type="primary"  style="font-size: 13px;"
               @click="handleClickReferenceForm(item)">{{map[item]}}
      <b v-if="index<value.length-1" style="margin: 0 4px">,</b>
    </el-link>
  </template>
  <!-- 多选、数组-->
  <template v-else-if="value instanceof Array ">
    <template v-if="value.length>0">
      <el-tag v-for="(item,itemIndex) in value" :key="itemIndex" effect="light" style="margin:2px 0 0 2px;">
        {{ item }}
      </el-tag>
    </template>
    <span v-else>--</span>
  </template>
  <!-- 多行文本 -->
  <template v-else-if="column.tag==='textarea'">
    <el-tooltip placement="top-start" :open-delay="500">
      <template #content>
        <div v-html="value?value.replaceAll('\n','<br/>'):''"></div>
      </template>
      <div class="oneLine">{{ value ? value : '--' }}</div>
    </el-tooltip>
  </template>
  <!-- 文本、数值 -->
  <template v-else>
    <span class="oneLine">{{ value || value === 0 ? value : '--' }}</span>
  </template>

  <app-form-view v-if="column.tag==='referenceForm' || column.tag==='referenceFormSelect'" ref="appFormView"></app-form-view>

</template>

<script>
import {mapStores} from "pinia";
import {useAppStore} from "@/stores/app";
import {useAttachmentStore} from "@/stores/attachment";
import {useFormStore} from "@/stores/form";
import {useUserStore} from "@/stores/user";
import {useDeptStore} from "@/stores/dept";
import attachmentItem from "@/components/design-form-list/attachmentItem.vue";
import AppFormView from "@/components/app-form/app-form-view.vue";
import UserCard from "@/components/common/userCard.vue";
import DeptCard from "@/components/common/deptCard.vue";

export default {
  components: {
    DeptCard,
    UserCard,
    AppFormView,
    attachmentItem
  },
  props: {
    value: {
      type: [String, Number, Boolean, Array],
    },
    column: {
      type: Object,
      default: () => {
        return {}
      }
    },
    /** table、detail 根据此值区分表格/详情展示形式 */
    container: {
      type: String,
      default: () => 'detail'
    }
  },
  data() {
    return {
      showValue: '',
      users: [],
      depts: [],
      map: {}
    }
  },
  created() {
    if (this.column.tag === 'referenceForm') {
      if (!this.value) {
        this.showValue = '--';
      } else {
        this.findTitleById(this.value)
      }
    } else if (this.column.tag === 'referenceFormSelect') {

      if (this.value) {
        this.value.forEach(item => {
          this.findColumnsById(item)
        })
      }

    } else if (this.column.tag === 'people') {
      this.findUserByIds();
    } else if (this.column.tag === 'dept') {
      this.findDeptByIds();
    }

  },
  computed: {
    ...mapStores(useAttachmentStore, useAppStore, useFormStore, useUserStore, useDeptStore)
  },
  methods: {
    toBreak(content) {
      let index = 26;
      while (index < content.length) {
        content = this.insertStr(content, index, '<br>');
        index += 30;
      }

      return content;
    },
    insertStr(soure, index, toBeInsert) {
      return soure.slice(0, index) + toBeInsert + soure.slice(index);
    },
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 15) {
        return value.slice(0, 10) + "..." + value.slice(value.length - 6, value.length);
      }
      return value;
    },
    showDate(value) {
      let html = '<div>';
      value.split(" ").forEach(item => html += item + '<br/>');
      html += '</div>'
      return html;
    },
    async findTitleById(data_id) {
      if(data_id){
        this.showValue = (await this.formStore.findTitleById(this.column.attrs.form_id, data_id)).data;
      }

    },
    async findColumnsById(id) {
      this.formStore.findColumnsById(this.column.attrs.form_id, id, this.column.attrs.show_columns).then((res) => {
        this.map[id] = res.data;
      })
    },
    handleClickReferenceForm(item) {

      if(this.column.attrs.form_id && (item || this.value)){
        this.$refs.appFormView.call(this.column.attrs.form_id, item || this.value)
      }

    },
    findUserByIds() {
      if (this.value) {

        if (typeof this.value === 'string') {
          this.userStore.findById(this.value).then(res => {
            this.users.push(res.data);
          })
        } else {
          this.value.forEach(userId => {
            this.userStore.findById(userId).then(res => {
              this.users.push(res.data);
            })
          })
        }
      }
    },
    findDeptByIds() {
      if (this.value) {
        if (typeof this.value === 'string') {
          this.deptStore.findById(this.value).then(res => {
            this.depts.push(res.data);
          })
        } else {
          this.value.forEach(deptId => {
            this.deptStore.findById(deptId).then(res => {
              this.depts.push(res.data);
            })
          })
        }
      }
    }
  },

}
</script>

<style scoped lang="scss">
.oneLine {
  overflow: hidden;
  white-space: nowrap;
}
</style>
