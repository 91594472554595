import {
    get,
    post,
    post2,
    put,
    put2,
    $delete
} from '@/plugins/axios.js'


export default {
    post(form_id, params) {
        return post2(`/api/form/${form_id}`, params);
    },
    temporaryStorage(form_id, params) {
        return post2(`/api/form/temporaryStorage/${form_id}`, params);
    },
    list(params) {
        return post(`/api/form/list`, params);
    },
    statistics(formId,params){
        return post(`/api/form/statistics/${formId}`, params);
    },
    put(form_id, params) {
        return put(`/api/form/${form_id}`, params);
    },
    delete(form_id, data_id, params) {
        return $delete(`/api/form/${form_id}/${data_id}`, params);
    },
    comment(form_id, data_id, params) {
        return post2(`/api/form/comment/${form_id}/${data_id}`, params);
    },
    findById(form_id, data_id, params) {
        return get(`/api/form/findById/${form_id}/${data_id}`, params);
    },
    listUser(params) {
        return get(`/api/form/listUser`, params);
    },
    referenceForm(params) {
        return get(`/api/form/referenceForm`, params);
    },
    findTitleById(params) {
        return get(`/api/form/findTitleById`, params);
    },
    findTitleByIds(params) {
        return get(`/api/form/findTitleByIds`, params);
    },
    findColumnsById(params) {
        return get(`/api/form/findColumnsById`, params);
    },
    findColumnsByIds(params) {
        return get(`/api/form/findColumnsByIds`, params);
    },
    getCoding(params) {
        return get(`/api/form/getCoding`, params);
    },
    setCoding(params) {
        return put2(`/api/form/setCoding`, params);
    },
    generateCoding(form_id, params) {
        return post2(`/api/form/generateCoding/${form_id}`, params);
    },
    getComment(form_id, data_id, params) {
        return get(`/api/form/getComment/${form_id}/${data_id}`, params);
    },
    getTimeline(form_id, data_id, params) {
        return get(`/api/form/getTimeline/${form_id}/${data_id}`, params);
    },
    getWorkFlow(form_id, data_id, params) {
        return get(`/api/form/getWorkFlow/${form_id}/${data_id}`, params);
    },
    property(formId,dataId,params) {
        return get(`/api/form/property/${formId}/${dataId}`, params);
    }

}
