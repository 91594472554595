import _ from 'lodash'

export const _default = {
    select: 'script',
    fixed: '',
    form: {},
    script: {
        value: '',
        html: '',
        text: '',
    }
}
export const _hide = {
    script: {
        value: '',
        html: '',
        text: ''
    }
}
export const _disabled = {
    script: {
        value: '',
        html: '',
        text: ''
    }
}
export const _ocrTypes = [
    {
        name: '身份证',
        describe: '支持对二代居民身份证正反面所有8个字段进行结构化识别，包括姓名、性别、民族、出生日期、住址、身份证号、签发机关、有效期限'
    },
    {
        name: '银行卡',
        describe: '支持对主流银行卡的卡号、有效期、发卡行、卡片类型、持卡人、银行卡号位置 6 个关键字段进行结构化识别。'
    },
    {
        name: '驾驶证',
        describe: '支持对机动车驾驶证正页及副页所有字段进行结构化识别。同时支持识别交管12123 APP 发放的电子驾驶证正页。'
    },
    {
        name: '行驶证',
        describe: '对机动车行驶证主页及副页所有22个字段进行结构化识别，包括号牌号码、车辆类型、所有人、品牌型号、车辆识别代码、发动机号码、核定载人数、质量、尺寸、检验记录等。'
    },
    {
        name: '增值税发票',
        describe: '支持普通发票、专用发票、电子普通发票、电子专用发票、区块链发票、通用机打电子发票'
    },
    {
        name: '通行费发票',
        describe: '支持通行费电子普票，且以主表形式提取'
    },
    {
        name: '定额发票',
        describe: '对各类定额发票的发票代码、发票号码、金额、发票所在地、发票金额小写、省、市7个关键字段进行结构化识别。'
    },
    {
        name: '营业执照',
        describe: '支持对不同版式营业执照的证件编号、社会信用代码、单位名称、地址、法人、类型、成立日期、有效日期、经营范围等关键字段进行结构化识别。'
    },
    {
        name: '火车票',
        describe: '支持对红、蓝火车票的13个关键字段进行结构化识别，包括车票号码、始发站、目的站、车次、日期、票价、席别、姓名、座位号、身份证号、售站、序列号、时间。'
    },
    {
        name: '机票行程单',
        describe: '支持对飞机行程单的24个字段进行结构化识别，包括电子客票号、印刷序号、姓名、始发站、目的站、航班号、日期、时间、票价、身份证号、承运人、民航发展基金、保险费、燃油附加费、其他税费、合计金额、填开日期、订票渠道、客票级别、座位等级、销售单位号、签注、免费行李、验证码。 同时，支持单张行程单上的多航班信息识别。'
    },
    {
        name: '汽车票',
        describe: '支持对全国范围不同版式汽车票的发票代码、发票号码、到达站、出发站、日期、时间、金额、身份证号、姓名、开票日期10个字段进行结构化识别。'
    },
    {
        name: '车牌',
        describe: '支持识别中国大陆机动车蓝牌、黄牌（单双行）、绿牌、大型新能源（黄绿）、领使馆车牌、警牌、武警牌（单双行）、军牌（单双行）、港澳出入境车牌、农用车牌、民航车牌、非机动车车牌（北京地区）的地域编号和车牌号'
    }
]
export const _ocrTypeColumns = {
    '身份证': [
        {name: '识别图片', from: '', to: ''},
        {name: '住址', from: '住址', to: '', process: (val) => val.words},
        {name: '公民身份号码', from: '公民身份号码', to: '', process: (val) => val.words},
        {name: '出生', from: '出生', to: '', process: (val) => val.words},
        {name: '姓名', from: '姓名', to: '', process: (val) => val.words},
        {name: '性别', from: '性别', to: '', process: (val) => val.words},
        {name: '民族', from: '民族', to: '', process: (val) => val.words},
        {name: '失效日期(副页)', from: '失效日期', to: '', process: (val) => val.words},
        {name: '签发机关(副页)', from: '签发机关', to: '', process: (val) => val.words},
        {name: '签发日期(副页)', from: '签发日期', to: '', process: (val) => val.words},
    ],
    '银行卡': [
        {name: '识别图片', from: '', to: ''},
        {name: '银行卡卡号', from: 'bank_card_number', to: '',},
        {name: '有效期', from: 'valid_date', to: '',},
        {
            name: '银行卡类型', from: 'bank_card_type', to: '', process: (val) => {
                switch (val) {
                    case 0:
                        return '不能识别';
                    case 1:
                        return '借记卡';
                    case 2:
                        return '贷记卡';
                    case 3:
                        return '准贷记卡';
                    case 4:
                        return '预付费卡';
                }
            }
        },
        {name: '银行名', from: 'bank_name', to: ''},
        {name: '持卡人姓名', from: 'holder_name', to: '',}
    ],
    '驾驶证': [
        {name: '识别图片', from: '', to: ''},
        {name: '姓名', from: '姓名', to: '', process: (val) => val.words},
        {name: '出生日期', from: '出生日期', to: '', process: (val) => val.words},
        {name: '住址', from: '住址', to: '', process: (val) => val.words},
        {name: '初次领证日期', from: '初次领证日期', to: '', process: (val) => val.words},
        {name: '国籍', from: '国籍', to: '', process: (val) => val.words},
        {name: '准驾车型', from: '准驾车型', to: '', process: (val) => val.words},
        {name: '性别', from: '性别', to: '', process: (val) => val.words},
        {name: '发证单位', from: '发证单位', to: '', process: (val) => val.words},
        {name: '有效期限起', from: '有效期限', to: '', process: (val) => val.words},
        {name: '有效期限止', from: '至', to: '', process: (val) => val.words},
        {name: '证号(副页)', from: '证号', to: '', process: (val) => val.words},
        {name: '档案编号(副页)', from: '档案编号', to: '', process: (val) => val.words},
    ],
    '行驶证': [
        {name: '识别图片', from: '', to: ''},
        {name: '车辆识别代号', from: '车辆识别代号', to: '', process: (val) => val.words},
        {name: '住址', from: '住址', to: '', process: (val) => val.words},
        {name: '发证日期', from: '发证日期', to: '', process: (val) => val.words},
        {name: '发证单位', from: '发证单位', to: '', process: (val) => val.words},
        {name: '品牌型号', from: '品牌型号', to: '', process: (val) => val.words},
        {name: '车辆类型', from: '车辆类型', to: '', process: (val) => val.words},
        {name: '所有人', from: '所有人', to: '', process: (val) => val.words},
        {name: '使用性质', from: '使用性质', to: '', process: (val) => val.words},
        {name: '发动机号码', from: '发动机号码', to: '', process: (val) => val.words},
        {name: '号牌号码', from: '号牌号码', to: '', process: (val) => val.words},
        {name: '注册日期', from: '注册日期', to: '', process: (val) => val.words},

        {name: '检验记录(副页)', from: '检验记录', to: '', process: (val) => val.words},
        {name: '核定载质量(副页)', from: '核定载质量', to: '', process: (val) => val.words},
        {name: '整备质量(副页)', from: '整备质量', to: '', process: (val) => val.words},
        {name: '外廓尺寸(副页)', from: '外廓尺寸', to: '', process: (val) => val.words},
        {name: '核定载人数(副页)', from: '核定载人数', to: '', process: (val) => val.words},
        {name: '总质量(副页)', from: '总质量', to: '', process: (val) => val.words},
        {name: '燃油类型(副页)', from: '燃油类型', to: '', process: (val) => val.words},
        {name: '准牵引总质量(副页)', from: '准牵引总质量', to: '', process: (val) => val.words},
        {name: '备注(副页)', from: '备注', to: '', process: (val) => val.words},
        {name: '档案编号(副页)', from: '档案编号', to: '', process: (val) => val.words},
        {name: '号牌号码(副页)', from: '号牌号码', to: '', process: (val) => val.words},
        {name: '证芯编号(副页)', from: '证芯编号', to: '', process: (val) => val.words},
    ],
    '增值税发票': [
        {name: '识别图片', from: '', to: ''},
        {name: '发票类型', from: 'InvoiceType', to: ''},
        {name: '发票代码', from: 'InvoiceCode', to: ''},
        {name: '发票号码', from: 'InvoiceNum', to: ''},
        {name: '数电票号', from: 'InvoiceNumDigit', to: ''},
        {name: '开票日期', from: 'InvoiceDate', to: ''},
        {name: '校验码', from: 'InvoiceCodeConfirm', to: ''},
        {name: '发票金额', from: 'AmountInFiguers', to: ''},
        {name: '大写金额', from: 'AmountInWords', to: ''},
        {name: '不含税金额', from: 'TotalAmount', to: ''},
        {name: '发票税额', from: 'TotalTax', to: ''},
        {name: '受票方名称', from: 'PurchaserName', to: ''},
        {name: '受票方税号', from: 'PurchaserRegisterNum', to: ''},
        {name: '受票方地址、电话', from: 'PurchaserAddress', to: ''},
        {name: '受票方开户行、账号', from: 'PurchaserBank', to: ''},
        {name: '销售方名称', from: 'SellerName', to: ''},
        {name: '销售方税号', from: 'SellerRegisterNum', to: ''},
        {name: '销售方地址、电话', from: 'SellerAddress', to: ''},
        {name: '销售方开户行、账号', from: 'SellerBank', to: ''},
        {name: '发票消费类型', from: 'ServiceType', to: ''},
        {name: '发票左上角标志', from: 'InvoiceTag', to: ''},
        {name: '发票名称', from: 'InvoiceTypeOrg', to: ''},
        {name: '开票人', from: 'NoteDrawer', to: ''},
        {name: '收款人', from: 'Payee', to: ''},
        {name: '备注', from: ' Remarks', to: ''},
        {name: '发票详单.名称', from: 'CommodityName', to: '', array: true},
        {name: '发票详单.规格型号', from: 'CommodityType', to: '', array: true},
        {name: '发票详单.单位', from: 'CommodityUnit', to: '', array: true},
        {name: '发票详单.数量', from: 'CommodityNum', to: '', array: true},
        {name: '发票详单.单价', from: 'CommodityPrice', to: '', array: true},
        {name: '发票详单.金额', from: 'CommodityAmount', to: '', array: true},
        {name: '发票详单.税率', from: 'CommodityTaxRate', to: '', array: true},
        {name: '发票详单.税额', from: 'CommodityTax', to: '', array: true},
    ],
    '通行费发票': [
        {name: '识别图片', from: '', to: ''},
        {name: '发票类型', from: 'InvoiceType', to: ''},
        {name: '发票代码', from: 'InvoiceCode', to: ''},
        {name: '发票号码', from: 'InvoiceNum', to: ''},
        {name: '开票日期', from: 'InvoiceDate', to: ''},
        {name: '发票金额', from: 'AmountInFiguers', to: ''},
        {name: '大写金额', from: 'AmountInWords', to: ''},
        {name: '不含税金额', from: 'TotalAmount', to: ''},
        {name: '发票税额', from: 'TotalTax', to: ''},
        {name: '受票方名称', from: 'PurchaserName', to: ''},
        {name: '受票方税号', from: 'PurchaserRegisterNum', to: ''},
        {name: '受票方地址、电话', from: 'PurchaserAddress', to: ''},
        {name: '受票方开户行、账号', from: 'PurchaserBank', to: ''},
        {name: '销售方名称', from: 'SellerName', to: ''},
        {name: '销售方税号', from: 'SellerRegisterNum', to: ''},
        {name: '销售方地址、电话', from: 'SellerAddress', to: ''},
        {name: '销售方开户行、账号', from: 'SellerBank', to: ''},
        {name: '发票消费类型', from: 'ServiceType', to: ''},
        {name: '发票左上角标志', from: 'InvoiceTag', to: ''},
        {name: '发票名称', from: 'InvoiceTypeOrg', to: ''},
        {name: '开票人', from: 'NoteDrawer', to: ''},
        {name: '收款人', from: 'Payee', to: ''},
        {name: '备注', from: ' Remarks', to: ''},
        {name: '名称', from: 'CommodityName', to: '', process: (val) => val.map(mapper => mapper.word).join('')},
        {
            name: '车牌号',
            from: 'CommodityPlateNum',
            to: '',
            process: (val) => val.map(mapper => mapper.word).join(''),
            describe: '仅通行费增值税电子普通发票含有此参数'
        },
        {name: '类型', from: 'CommodityType', to: '', process: (val) => val.map(mapper => mapper.word).join('')},
        {
            name: '通行日期起',
            from: 'CommodityStartDate',
            to: '',
            process: (val) => val.map(mapper => mapper.word).join(''),
            describe: '仅通行费增值税电子普通发票含有此参数'
        },
        {
            name: '通行日期止',
            from: 'CommodityEndDate',
            to: '',
            process: (val) => val.map(mapper => mapper.word).join(''),
            describe: '仅通行费增值税电子普通发票含有此参数'
        },
        {name: '金额', from: 'CommodityAmount', to: '', process: (val) => val.map(mapper => mapper.word).join('')},
        {name: '税率', from: 'CommodityTaxRate', to: '', process: (val) => val.map(mapper => mapper.word).join('')},
    ],
    '定额发票': [
        {name: '识别图片', from: '', to: ''},
        {name: '发票代码', from: 'invoice_code', to: ''},
        {name: '发票号码', from: 'invoice_number', to: ''},
        {name: '发票金额', from: 'invoice_rate_lowercase', to: ''},
        {name: '发票所在地', from: 'location', to: ''},
        {name: '省', from: 'province', to: ''},
        {name: '市', from: 'city', to: ''},
    ],
    '火车票': [
        {name: '识别图片', from: '', to: ''},
        {name: '车票号', from: 'ticket_num', to: ''},
        {name: '始发站', from: 'starting_station', to: ''},
        {name: '车次号', from: 'train_num', to: ''},
        {name: '到达站', from: 'destination_station', to: ''},
        {name: '出发日期', from: 'date', to: ''},
        {name: '车票金额', from: 'ticket_rates', to: '',process: (val) => parseFloat(val.match(/\d+\.\d+/)[0])},
        {name: '席别', from: 'seat_category', to: ''},
        {name: '乘客姓名', from: 'name', to: ''},
        {name: '身份证号', from: 'id_num', to: ''},
        {name: '序列号', from: 'serial_number', to: ''},
        {name: '售站', from: 'sales_station', to: ''},
        {name: '时间', from: 'time', to: ''},
        {name: '座位号', from: 'seat_num', to: ''},
    ],
    '汽车票': [
        {name: '识别图片', from: '', to: ''},
        {name: '发票代码', from: 'InvoiceCode', to: ''},
        {name: '发票号码', from: 'InvoiceNum', to: ''},
        {name: '日期', from: 'Date', to: ''},
        {name: '时间', from: 'Time', to: ''},
        {name: '出发站', from: 'StartingStation', to: ''},
        {name: '金额', from: 'Fare', to: ''},
        {name: '身份证号', from: 'IdNum', to: ''},
        {name: '到达站', from: 'DestinationStation', to: ''},
        {name: '姓名', from: 'Name', to: ''},
    ],
    '机票行程单': [
        {name: '识别图片', from: '', to: ''},
        {name: '姓名', from: 'name', to: ''},
        {name: '始发站', from: 'starting_station', to: ''},
        {name: '目的站', from: 'destination_station', to: ''},
        {name: '航班号', from: 'flight', to: ''},
        {name: '日期', from: 'date', to: ''},
        {name: '电子客票号码', from: 'ticket_number', to: ''},
        {name: '票价', from: 'fare', to: ''},
        {name: '民航发展基金/基建费', from: 'dev_fund', to: ''},
        {name: '燃油附加费', from: 'fuel_surcharge', to: ''},
        {name: '其他税费', from: 'other_tax', to: ''},
        {name: '合计金额', from: 'ticket_rates', to: ''},
        {name: '填开日期', from: 'issued_date', to: ''},
        {name: '身份证号', from: 'id_num', to: ''},
        {name: '承运人', from: 'carrier', to: ''},
        {name: '时间', from: 'time', to: ''},
        {name: '销售单位号', from: 'agent_code', to: ''},
        {name: '签注', from: 'endorsement', to: ''},
    ],
    '营业执照': [
        {name: '识别图片', from: '', to: ''},
        {name: '经营范围', from: '经营范围', to: '', process: (val) => val.words},
        {name: '组成形式', from: '组成形式', to: '', process: (val) => val.words},
        {name: '法人', from: '法人', to: '', process: (val) => val.words},
        {name: '证件编号', from: '证件编号', to: '', process: (val) => val.words},
        {name: '注册资本', from: '注册资本', to: '', process: (val) => val.words},
        {name: '单位名称', from: '单位名称', to: '', process: (val) => val.words},
        {name: '有效期', from: '有效期', to: '', process: (val) => val.words},
        {name: '社会信用代码', from: '社会信用代码', to: '', process: (val) => val.words},
        {name: '实收资本', from: '实收资本', to: '', process: (val) => val.words},
        {name: '有效期起始日期', from: '有效期起始日期', to: '', process: (val) => val.words},
        {name: '成立日期', from: '成立日期', to: '', process: (val) => val.words},
        {name: '税务登记号', from: '税务登记号', to: '', process: (val) => val.words},
        {name: '地址', from: '地址', to: '', process: (val) => val.words},
        {name: '登记机关', from: '登记机关', to: '', process: (val) => val.words},
        {name: '类型', from: '类型', to: '', process: (val) => val.words},
    ],
    '车牌': [
        {name: '识别图片', from: '', to: ''},
        {name: '车牌号码', from: 'number', to: ''},
        {name: '颜色', from: 'color', to: ''},
    ]
};
export const items = [

    {
        group: '基础控件',
        children: [
            {
                tag: 'input',
                type: 'basic',
                name: '单行文本',
                icon: 'Tickets',
                label: '单行文本',
                describe: '',
                value: '',
                default: {},
                attrs: {
                    unique: false,
                    placeholder: '请输入'
                }
            },
            {
                tag: 'textarea',
                type: 'basic',
                name: '多行文本',
                icon: 'Postcard',
                label: '多行文本',
                describe: '',
                value: '',
                default: {},
                attrs: {
                    rows: 3,
                    placeholder: '请输入'
                }
            },
            {
                tag: 'date',
                type: 'basic',
                name: '日期',
                icon: 'Timer',
                label: '日期',
                default: {},
                describe: '',
                value: '',
                attrs: {
                    format: 'YYYY',
                    formatOptions: [
                        {label: '年-月-日', value: 'YYYY-MM-DD'},
                        {label: '年-月', value: 'YYYY-MM'},
                        {label: '年', value: 'YYYY'},
                        {label: '年-月-日 时:分', value: 'YYYY-MM-DD HH:mm'},
                        // {label: '时:分', value: 'HH:mm'},
                    ]
                }
            },
            {
                tag: 'number',
                type: 'basic',
                name: '数字',
                icon: 'GoldMedal',
                label: '数字',
                default: _default,
                describe: '',
                value: 0,
                attrs: {
                    scale: {
                        enable: false,
                        value: 2
                    }
                }
            },
            {
                tag: 'radio',
                type: 'basic',
                name: '单选',
                icon: 'Pointer',
                label: '单选',
                describe: '',
                value: '',
                default: {},
                attrs: {
                    options: ['选项1', '选项2', '选项3']
                }
            },
            {
                tag: 'checkbox',
                type: 'basic',
                name: '多选',
                icon: 'CircleCheck',
                label: '多选',
                describe: '',
                value: [],
                default: {},
                attrs: {
                    options: ['选项1', '选项2', '选项3']
                }
            },
            {
                tag: 'select',
                type: 'basic',
                name: '下拉框',
                icon: 'DCaret',
                label: '下拉框',
                describe: '',
                value: '',
                default: {},
                attrs: {
                    options: ['选项1', '选项2', '选项3']
                }
            },
            {
                tag: 'switch',
                type: 'basic',
                name: '是/否',
                icon: 'TurnOff',
                label: '是/否',
                describe: '',
                value: false,
                default: {},
                attrs: {}
            },
            {
                tag: 'attachment',
                type: 'basic',
                name: '附件',
                icon: 'Film',
                label: '附件',
                describe: '',
                value: [],
                default: {},
                attrs: {
                    fileSize: 10,
                    fileTypes: ['jpg', 'jpeg', 'png', 'xlsx', 'xls', 'pdf'],
                }
            },
            {
                tag: 'image',
                type: 'basic',
                name: '图片',
                icon: 'Picture',
                label: '图片',
                describe: '',
                value: [],
                default: {},
                attrs: {
                    fileSize: 10,
                }
            },
            {
                tag: 'address',
                type: 'basic',
                name: '地址',
                icon: 'LocationInformation',
                label: '地址',
                describe: '',
                value: [],
                default: {},
                attrs: {
                    level: 3,
                    formatOptions: [
                        {label: '省-市-县', value: 3},
                        {label: '省-市', value: 2},
                        {label: '省', value: 1}],
                }
            },
            {
                tag: 'subForm',
                type: 'basic',
                name: '子表单',
                icon: 'DocumentCopy',
                label: '子表单',
                describe: '',
                value: [],
                default: {},
                children: [],
                attrs: {
                    height:300,
                    initialRows:1,
                    scrollbar_always_on: false,
                    title: []
                }
            },
            {
                tag: 'people',
                type: 'basic',
                name: '人员',
                icon: 'Avatar',
                label: '人员',
                describe: '',
                value: '',
                default: {},
                attrs: {
                    multiple: false
                }
            },
            {
                tag: 'dept',
                type: 'basic',
                name: '部门',
                icon: 'Stamp',
                label: '部门',
                describe: '',
                value: '',
                default: {},
                attrs: {
                    multiple: false
                }
            },

        ]
    },
    {
        group: '布局控件',
        children: [
            {
                name: '分组标题',
                tag: 'groupTitle',
                type: 'layout',
                label: '分组标题',
                icon: 'Guide',
            },
            {
                name: '行容器',
                tag: 'hbox',
                type: 'layout',
                label: '行容器',
                icon: 'Files',
                list1: [],
                list2: [],
                list3: [],
                list4: [],
                attrs: {
                    cols: 2, /** 2-4 */
                    format: 0,
                    formatOptions: {
                        "2": [['1/2', '1/2'], ['1/3', '2/3'], ['2/3', '1/3'], ['1/4', '3/4'], ['3/4', '1/4']],
                        "3": [['1/3', '1/3', '1/3'], ['1/4', '1/4', '1/2'], ['1/2', '1/4', '1/4'], ['1/4', '1/2', '1/4']],
                        "4": [['1/4', '1/4', '1/4', '1/4']],
                    }
                }
            },
            {
                name: '描述说明',
                tag: 'description',
                type: 'layout',
                label: '描述说明',
                icon: 'Brush',
                value: '这是一段描述',
                attrs: {
                    title: '',
                    type: ''
                }
            },
        ]

    },
    {
        group: '系统组件',
        children: [
            {
                tag: 'coding',
                type: 'basic',
                name: '流水号',
                icon: 'ShoppingCart',
                label: '流水号',
                describe: '',
                value: '',
                default: {},
                attrs: {
                    rule: [
                        {
                            type: 'date',
                            value: 'yyyy',/*  年、年月、年月日 */
                        },
                        {
                            type: 'increment',
                            value: 8,
                            reset: '', /* false/year */
                        },
                        // {
                        //     type:'form',
                        //     value:'',
                        // }
                    ]
                }
            },
        ],
    },
    {
        group: '高级控件',
        children: [
            {
                tag: 'referenceForm',
                type: 'basic',
                name: '关联表单',
                icon: 'Postcard',
                label: '关联表单',
                describe: '',
                value: '',
                default: {},
                attrs: {
                    form_id: '',
                    parent: '',
                    fillRole: {
                        mode: 'default',
                        main: [],
                        subForms: [],
                    }
                }
            },
            {
                tag: 'referenceFormSelect',
                type: 'basic',
                name: '关联表单多选',
                icon: 'Collection',
                label: '关联表单多选',
                describe: '',
                value: [],
                default: {},
                attrs: {
                    form_id: '',
                    show_columns: [],
                }
            },
            // {
            //     tag: 'referenceAttribute',
            //     type: 'basic',
            //     name: '关联属性',
            //     icon: 'Files',
            //     label: '关联属性',
            //     describe: '',
            //     value: '',
            //     default: {},
            //     attrs: {
            //         form_id: '',
            //     }
            // },
            {
                tag: 'ocr',
                type: 'basic',
                name: '文字识别',
                icon: 'Money',
                label: '文字识别',
                describe: '',
                value: '',
                default: {},
                attrs: {
                    type: '增值税发票',
                    parent: '',
                    operation: _.cloneDeep(_ocrTypeColumns.增值税发票)

                }
            },
            // {
            //     tag: 'api',
            //     type: 'basic',
            //     name: 'REST接口',
            //     icon: 'Magnet',
            //     label: 'REST接口',
            //     describe: '',
            //     value: '',
            //     default: {},
            //     attrs: {}
            // },
        ],
    }
];
export const _upload_types = ['png', 'jpg', 'jpeg', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'zip', 'rar'];
export const expend = function (element) {

    if (element.tag === 'subForm' && element.attrs === undefined) {
        element.attrs = {
            title: []
        };
    }

    if (element.default.select === undefined) {
        element.default = _.cloneDeep(_default);
    }
    if (element.hide === undefined) {
        element.hide = _.cloneDeep(_hide);
    }
    if (element.disabled === undefined) {
        element.disabled = _.cloneDeep(_disabled);
    }

}