<template>

  <el-row :gutter="20" style="margin: 10px 0">
    <el-col v-for="index in element.attrs.cols" :key=index :span="span(index)">
      <component v-if="element[`list${index}`].length>0 && context.authentication.isRead(element[`list${index}`][0])"
                 v-show="context.authentication.isHide(element[`list${index}`][0],context.data)"
                 :is="`design${element[`list${index}`][0].tag.charAt(0).toUpperCase() + element[`list${index}`][0].tag.slice(1)}Preview`"
                 :context="{
                   form:context.form,
                   parent:element,
                   data:context.data,
                   node:context.node,
                   authentication:context.authentication,
                 }"
                 :element="element[`list${index}`][0]">
      </component>
    </el-col>
  </el-row>

</template>

<script>

export default {
  props: {
    modelValue: {
      type: String,
      default: () => ""
    },
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {}
  },
  created() {

  },
  methods: {
    span(index) {

      try {
        return 24 * eval(this.element.attrs.formatOptions[this.element.attrs.cols.toString()][this.element.attrs.format][index - 1])
      } catch (e) {
        return 24 / this.element.cols;
      }

    },
  }
}

</script>

<style scoped lang="scss">

</style>