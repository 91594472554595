<template>
  <div>
    <el-upload :action="action" :headers="headers" :disabled="disabled" list-type="picture-card" :show-file-list="true" :file-list="list" :data="params"
               :before-upload="beforeUpload" :on-success="onSuccess" :on-progress="onProcess">
      <el-icon><Plus/></el-icon>
      <template #file="{file}">
        <div style="width: 100%;text-align: center">
          <el-progress v-if="!file.file_name" type="circle" :percentage="uploadPercent" :width="95" style="padding-left: 30px"></el-progress>
          <el-image v-if="file.type==='image'" class="el-upload-list__item-thumbnail" :src="appStore.downloadUrl(file.id)" fit="fill"/>
          <div v-else-if="file.type==='word'" class="file-item" style="background-color: rgb(93,173,248);">
            <h6 style="padding-top: 30%;font-size: 12px;line-height: 1.6">{{ ellipsis(file.file_name) }}</h6>
          </div>
          <div v-else-if="file.type==='pdf'" class="file-item" style="background-color: #EB606D;">
            <h6 style="padding-top: 30%;font-size: 12px;line-height: 1.6">{{ ellipsis(file.file_name) }}</h6>
          </div>
          <div v-else-if="file.type==='excel'" class="file-item" style="background-color: #67B874;">
            <h6 style="padding-top: 30%;font-size: 12px;line-height: 1.6">{{ ellipsis(file.file_name) }}</h6>
          </div>
          <div v-else-if="file.type==='zip' || file.type==='rar'" class="file-item" style="background-color: yellow;">
            <h6 style="padding-top: 30%;font-size: 12px;line-height: 1.6">{{ ellipsis(file.file_name) }}</h6>
          </div>
          <div v-else class="file-item" style="background-color: white;color: black;">
            <h6 style="padding-top: 30%;font-size: 12px;line-height: 1.6">{{ ellipsis(file.file_name) }}</h6>
          </div>
          <span class="el-upload-list__item-actions">
            <span v-if="attachmentStore.isCanReadFileType(file.type)" class="el-upload-list__item-preview" @click="handlePreview(file)">
              <el-icon><zoom-in/></el-icon>
            </span>
            <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
              <el-icon><Download/></el-icon>
            </span>
            <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
              <el-icon><Delete/></el-icon>
            </span>
        </span>
        </div>
      </template>
    </el-upload>
    <el-image-viewer v-if="preview_show" :urlList="preview_list" @close="preview_show = false"  :initialIndex="preview_index" :teleported="true"></el-image-viewer>
    <attachment-viewer ref="attachmentViewer"></attachment-viewer>
  </div>

</template>

<script>
import attachmentViewer from "@/components/common/attachmentViewer.vue";
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/user";
import {useAttachmentStore} from "@/stores/attachment";
import {useAppStore} from "@/stores/app";
import * as imageConversion from 'image-conversion';

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    fileFormat: {
      type: Array,
      default: () => ['png', 'jpg','jpeg', 'doc', 'docx', 'pdf','xls','xlsx','zip','rar']
    },
    fileSize: {
      type: Number,
      default: () => 20
    },
    imageSize:{
      type: Number,
      default: () => 0.25
    },
    maxLength: {
      type: Number,
      default: () => 99
    },
    disabled:{
      type:Boolean,
      default:()=>false
    },
    params:{
      type: Object,
      default:()=>{}
    }
  },
  computed: {
    ...mapStores(useUserStore,useAttachmentStore,useAppStore)
  },
  components:{
    attachmentViewer
  },
  watch:{
    modelValue:{
      handler(newVal,oldVal){
        this.list = newVal;
      },
      deep:true
    }
  },
  created() {
    if (this.modelValue) {
      this.list = this.modelValue
    }
    this.action =  this.attachmentStore.action;
    this.headers.Authorization = this.userStore.getToken
  },
  data() {
    return {
      action: '',
      headers: {
        Authorization: ''
      },
      list: [],
      preview_show: false,
      preview_list: [],
      uploadPercent: 0,
      preview_index: 0,
    };
  },
  methods: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 15) {
        return value.slice(0, 10) + ".." + value.slice(value.length - 5, value.length);
      }
      return value;
    },
    handleRemove(file) {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i] === file) {
          this.list.splice(i, 1);
          break;
        }
      }

      this.$emit('update:modelValue', this.list)
    },
    handlePreview(file) {
      if (file.type === 'image') {
        let url =  this.appStore.downloadUrl(file.id) ;
        this.preview_show = true;
        this.preview_list = this.list.filter(filter=>filter.type === 'image').map(item =>this.appStore.downloadUrl(item.id) );
        this.preview_index = this.preview_list.indexOf(url)
      }else{
        this.$refs.attachmentViewer.call(file.id,file.file_name)
      }
    },
    handleDownload(file) {
      this.attachmentStore.download(file.id,file.file_name);
    },
    beforeUpload(file) {

      return new Promise((resolve, reject) => {

        if (this.list.length >= this.maxLength) {
          this.message('超出文件数量限制' + this.maxLength,'error');
          reject();
        }
        let file_format_check = false;
        for (let i = 0; i < this.fileFormat.length; i++) {
          if (file.name.toLowerCase().endsWith(this.fileFormat[i])) {
            file_format_check = true;
            break;
          }
        }
        if (!file_format_check) {
          this.message(`上传仅支持 ${this.fileFormat.toString().toUpperCase()} 格式`,'error');
          reject();
        }
        if (!(file.size / 1024 / 1024 < this.fileSize)) {
          this.message('上传文件大小不能超过 ' + this.fileSize + 'MB!','error');
          reject();
        }

        if (file.name.toLowerCase().endsWith('png') || file.name.toLowerCase().endsWith('jpg')  || file.name.toLowerCase().endsWith('jpeg')) {
          imageConversion.compressAccurately(file, {
            size: this.imageSize*1024,
            type: file.type,
            scale:Math.min(1/(file.size / parseInt(this.imageSize*1024*1024)),1),
          }).then(res => {
            resolve(res)
          })
        } else {
          resolve(file)
        }
      })
    },
    onSuccess(res) {
      this.list.push(res.data)
      this.list = this.deepClone(this.list)
      this.$emit('update:modelValue', this.list)
    },
    onProcess(event, file, fileList) {
      this.uploadPercent = Math.floor(event.percent)
    },

  },
}
</script>

<style scoped lang="scss">
h6 {
  padding: 0;
  margin: 0;
}

:deep( .el-upload-list--picture-card .el-upload-list__item) {
  width: 102px;
  height: 102px;
}

:deep( .el-upload--picture-card) {
  width: 102px;
  height: 102px;
  line-height: 100px;
}

.file-item {
  height: 100px;
  color: white;
  text-align: center;
}

.file-item > h6 {
  padding-top: 30%;
  font-size: 12px;
  line-height: 1.6
}


</style>
