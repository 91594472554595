<template>
  <el-upload :action="action" :headers="headers" :disabled="disabled" :file-list="list"
             :before-upload="beforeUpload" :on-success="onSuccess" :on-progress="onProcess" :data="params">
    <slot>
      <el-button v-prevent-re-click icon="Upload">点击上传</el-button>
    </slot>
    <template #file="{file}">

      <div style="display: flex;align-items: center;">
          <span style="flex: 1;display: flex;align-items: center">
             <el-avatar v-if="file.type==='image'" @click="handlePreview(file)" :size="35" shape="square"
                        :src="appStore.downloadUrl(file.id)" fit="fill"/>
            <el-button v-prevent-re-click v-else icon="Link" @click="handlePreview(file)" type="primary" style="font-size: 14px"
                       link></el-button>
            <span style="margin-left: 4px;font-size: 13px;cursor: pointer"
                  @click="handlePreview(file)">{{ ellipsis(file.file_name) }}</span>
          </span>
        <span>
           <el-button v-prevent-re-click @click="handleRemove(file)" icon="Close" type="danger" link></el-button>
        </span>

      </div>
    </template>
  </el-upload>
  <el-image-viewer v-if="preview_show" :urlList="preview_list" @close="preview_show = false"
                   :initialIndex="preview_index" :teleported="true"></el-image-viewer>
  <attachment-viewer ref="attachmentViewer"></attachment-viewer>
</template>

<script>
import attachmentViewer from "@/components/common/attachmentViewer.vue";
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/user";
import {useAttachmentStore} from "@/stores/attachment";
import {useAppStore} from "@/stores/app";
import * as imageConversion from 'image-conversion';

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    fileFormat: {
      type: Array,
      default: () => ['png', 'jpg', 'jpeg','doc', 'docx', 'pdf', 'xls', 'xlsx','zip','rar']
    },
    fileSize: {
      type: Number,
      default: () => 20
    },
    imageSize:{
      type: Number,
      default: () => 0.25
    },
    maxLength: {
      type: Number,
      default: () => 99
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    params:{
      type: Object,
      default:()=>{}
    }
  },
  computed: {
    ...mapStores(useUserStore, useAttachmentStore, useAppStore)
  },
  components: {
    attachmentViewer
  },
  watch: {
    modelValue: {
      handler(newVal, oldVal) {
        this.list = newVal;
      },
      deep: true
    }
  },
  created() {
    if (this.modelValue) {
      this.list = this.modelValue
    }
    this.action = this.attachmentStore.action;
    this.headers.Authorization = this.userStore.getToken
  },
  data() {
    return {
      action: '',
      headers: {
        Authorization: ''
      },
      list: [],
      preview_show: false,
      preview_list: [],
      uploadPercent: 0,
      preview_index: 0,
    };
  },
  methods: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 13) {
        return value.slice(0, 8) + ".." + value.slice(value.length - 5, value.length);
      }
      return value;
    },
    handleRemove(file) {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i] === file) {
          this.list.splice(i, 1);
          break;
        }
      }

      this.$emit('update:modelValue', this.list)
    },
    handlePreview(file) {
      if(this.isCanReadFileType(file.type)){
        if (file.type === 'image') {
          let url = this.appStore.downloadUrl(file.id);
          this.preview_show = true;
          this.preview_list = this.list.filter(filter => filter.type === 'image').map(item => this.appStore.downloadUrl(item.id));
          this.preview_index = this.preview_list.indexOf(url)
        } else {
          this.$refs.attachmentViewer.call(file.id, file.file_name)
        }
      }else{
        this.message('该文件类型不支持预览')
      }


    },
    handleDownload(file) {
      this.attachmentStore.download(file.id, file.file_name);
    },
    beforeUpload(file) {


      return new Promise((resolve, reject) => {

        if (this.list.length >= this.maxLength) {
          this.message('超出文件数量限制' + this.maxLength, 'error');
          reject();
        }

        let file_format_check = false;

        for (let i = 0; i < this.fileFormat.length; i++) {
          if (file.name.toLowerCase().endsWith(this.fileFormat[i])) {
            file_format_check = true;
            break;
          }
        }

        if (!file_format_check) {
          this.message(`上传仅支持 ${this.fileFormat.toString().toUpperCase()} 格式`,'error');
          reject();
        }

        if (!(file.size / 1024 / 1024 < this.fileSize)) {
          this.message('上传文件大小不能超过 ' + this.fileSize + 'MB!', 'error');
          reject();
        }


        if (file.name.toLowerCase().endsWith('png') || file.name.toLowerCase().endsWith('jpg')  || file.name.toLowerCase().endsWith('jpeg')) {
          imageConversion.compressAccurately(file, {
            size: this.imageSize*1024,
            type: file.type,
            scale:Math.min(1/(file.size / parseInt(this.imageSize*1024*1024)),1),
          }).then(res => {
            resolve(res)
          })
        } else {
          resolve(file)
        }


      })


    },
    onSuccess(res) {
      this.list.push(res.data)
      this.list = this.deepClone(this.list)
      this.$emit('update:modelValue', this.list)
    },
    onProcess(event, file, fileList) {
      this.uploadPercent = Math.floor(event.percent)
    },
    isCanReadFileType(type) {
      return type === 'image' || type === 'pdf' || type === 'word' || type === 'excel';
    }
  },
}
</script>

<style scoped lang="scss">


</style>
